import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./ProductOffer.module.scss";
import {
  Country,
  Currency,
  ProductItem,
  TerminalType,
} from "../../data/models/ContractTypes";
import { Image } from "../Image/Image";
import { useTranslation } from "react-i18next";
import { formattedPrice } from "../FormattedPrice/FormattedPrice";
import { QuantityInput } from "../../modules/Forms/QuantityInput";
import { Dynamic, Speed } from "../Animations/Dynamic";
import { SalesForceDiscountEntry } from "../../data/dataCheckout";

interface Props {
  terminalType: TerminalType;
  quantity: number;
  price: number | undefined;
  discount?: SalesForceDiscountEntry;
  currency: string;
  voucher?: ReactNode;
  onClick?: (quantity: number) => void;
  showBrands?: boolean;
  country: Country;
  show?: boolean;
}

export const ProductOffer: React.FunctionComponent<Props> = ({
  terminalType,
  price,
  discount,
  currency,
  voucher = null,
  showBrands = false,
  country,
  onClick,
  quantity,
  show = true,
}) => {
  const { t, i18n } = useTranslation();

  const finalPrice = discount?.discountPercent
    ? (price || 0) * (1 - discount.discountPercent / 100)
    : price;

  return (
    <Dynamic name={show ? "show" : "hide"} speed={Speed.NORMAL}>
      {show ? (
        <div className={styles.wrapper}>
          <div className={styles.productWrapper}>
            <div className={cx(styles.thumbnail)}>
              <ProductImage terminalType={terminalType} />
            </div>
            <div className={styles.info}>
              <div>
                <b className="text-12">{ProductItem[terminalType].name}</b>

                <div className="text-500">
                  {t("{{price}}/month", {
                    price: finalPrice
                      ? formattedPrice(finalPrice, currency, i18n.language)
                      : "-",
                  })}
                  {voucher && <div className="mt-1">{voucher}</div>}
                </div>

                <div>
                  <div className="passive mt-1 text-09">
                    {!!discount?.validMonths && (
                      <div>
                        {t("{{price}}/month after {{months}} months", {
                          price: price
                            ? formattedPrice(price, currency, i18n.language)
                            : "-",
                          months: discount.validMonths,
                        })}
                      </div>
                    )}
                    <div>
                      {t("Binding time: {{months}} months", {
                        months: ProductItem[terminalType].bindingTime,
                      })}
                    </div>
                    <div>
                      {t("Start fee: {{price}}", {
                        price: formattedPrice(
                          ProductItem[terminalType].startFee,
                          Currency[country],
                          i18n.language
                        ),
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <QuantityInput
                  min={0}
                  value={quantity}
                  onChange={(quantity) => {
                    onClick && onClick(quantity);
                  }}
                  name={`${terminalType}-quantity`}
                  hideMessages
                />
              </div>
              {showBrands && (
                <div className={cx(styles.cardBrands, "mt-2")}>
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                  <img src="https://placehold.it/40x30" alt="Card brand" />
                </div>
              )}
              {/* <div className="text-08 passive">
          <TerminalDescription terminalType={terminalType} />
        </div> */}
            </div>
          </div>
        </div>
      ) : null}
    </Dynamic>
  );
};

export const ProductImage: React.FunctionComponent<{
  terminalType: TerminalType;
}> = ({ terminalType }) => {
  return (
    <div className={styles.productImage}>
      <Image {...ProductItem[terminalType]} />
    </div>
  );
};
