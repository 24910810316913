import React from "react";
import { useTranslation } from "react-i18next";
import { Country } from "../../data/models/ContractTypes";
import { Link } from "../../components/Link/Link";

interface Props {
  country: Country;
}

const LinksProducts: Record<Country, string> = {
  [Country.SWEDEN]:
    "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-one-and-worldline-grow-general-terms-and-conditions.pdf",
  [Country.DENMARK]:
    "https://support.worldline.com/content/dam/worldline/local/da-dk/documents/Bambora-One-and-Bambora-Grow-General-Terms-and-Conditions.pdf",
  [Country.NORWAY]:
    "https://worldline.com/content/dam/worldline/local/no-no/documents/bambora-One-and-bambora-Grow-General-Terms-and-Conditions.pdf",
  [Country.FINLAND]:
    "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-one-and-worldline-grow-general-terms-and-conditions.pdf",
  [Country.GB]:
    "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-one-and-worldline-grow-general-terms-and-conditions.pdf",
};

export const TermsAndConditions: React.FunctionComponent<Props> = ({
  country,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h5>{t("Terms & conditions")}</h5>
      <div className="small mt-1">
        {t(
          "This application form along with the conditions and regulations available at worldline.com constitutes the entire agreement. Signing this agreement will confirm that we have accepted these conditions"
        )}
        :{" "}
        <Link to={LinksProducts[country]} external>
          {t("Terms and Conditions")}
        </Link>
      </div>
    </>
  );
};
