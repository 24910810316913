import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  ReactNode,
  useMemo,
} from "react";
import styles from "./Identification.module.scss";
import cx from "classnames";
import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { Spinner } from "../../../components/Spinner/Spinner";
import { dataSession, InitLogin, LoginStatus } from "../../../data/dataSession";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { useSearch } from "../../../hooks/useSearch";
import { QueryKey, SearchParams } from "../../../data/Store";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import {
  ContractProgress,
  Country,
  Language,
  TerminalType,
} from "../../../data/models/ContractTypes";
import { dataStartOnboarding } from "../../../data/dataStartOnboarding";
import { GlobalContext, GlobalProps } from "../../..";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components/Buttons/Button";
import { Status } from "../../../modules/Forms/FormContext";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { BankIdLogo } from "../../../components/Logos/BankIdLogo";
import { MittIdLogo } from "../../../components/Logos/MitIdLogo";
import { NBankIdLogo } from "../../../components/Logos/NBankIdLogo";
import { FtnLogo } from "../../../components/Logos/FtnLogo";
import { FakeLogin } from "./FakeLogin";
import { dataContract } from "../../../data/dataContract";
import { whereTo } from "./SignIn";
import { OnboardingPath } from "../routes";
import { LuCheckSquare } from "react-icons/lu";

const POLL_TIMER = 3000;
const MAX_NUMBER_OF_TRIES = 10;

interface Props {
  loginError: boolean;
  loginPending: boolean;
  onLogin: UseMutateFunction<InitLogin, Error, InitLoginRequest, unknown>;
}

type ImageType = {
  alt: string;
  src: string;
  ratio: number;
  className: string;
  name: string;
};

export enum SigningMethod {
  BANKID = "BANKID",
  NBANKID = "NBANKID",
  MITID = "MITID",
  FTN = "FTN",
}

export const CountryToMethod: Record<Country, SigningMethod> = {
  [Country.SWEDEN]: SigningMethod.BANKID,
  [Country.DENMARK]: SigningMethod.MITID,
  [Country.NORWAY]: SigningMethod.NBANKID,
  [Country.FINLAND]: SigningMethod.FTN,
  [Country.GB]: SigningMethod.BANKID,
};

export const Logos: Record<SigningMethod, ImageType> = {
  [SigningMethod.BANKID]: {
    className: styles[SigningMethod.BANKID],
    alt: "BankID logo",
    src: "/logos/BankID_619.2x513.svg",
    ratio: 619.2 / 513,
    name: "BankID",
  },
  [SigningMethod.NBANKID]: {
    className: styles[SigningMethod.NBANKID],
    alt: "BankID logo",
    src: "/logos/Norwegian_bankid_140x21.svg",
    ratio: 140 / 21,
    name: "BankID",
  },
  [SigningMethod.MITID]: {
    className: styles[SigningMethod.MITID],
    alt: "MitId logo",
    src: "/logos/MitID_1280x333.63934.svg",
    ratio: 1280 / 333.63934,
    name: "MitID",
  },
  [SigningMethod.FTN]: {
    className: styles[SigningMethod.FTN],
    alt: "FTN logo",
    src: "/logos/FTN_152x40.svg",
    ratio: 152 / 40,
    name: "Finnish Trust Network",
  },
};

interface PollProps extends Props {
  resetBoundary: () => void;
  resetQuery: () => void;
  sessionId: string;
}

export const Identification: React.FunctionComponent<{
  resetQuery: () => void;
  resetBoundary: () => void;
}> = ({ resetQuery, resetBoundary }) => {
  const search = useSearch();

  const {
    isError: loginError,
    isPending: loginPending,
    mutate: onLogin,
  } = useMutation({
    mutationFn: dataSession.initLogin,
    onSuccess: (resp) => (window.location.href = resp.url),
  });

  if (search[QueryKey.SESSION]) {
    return (
      <Wrapper>
        <div className="mt-5">
          <Poll
            loginError={loginError}
            loginPending={loginPending}
            onLogin={onLogin}
            sessionId={search[QueryKey.SESSION]}
            resetBoundary={resetBoundary}
            resetQuery={resetQuery}
          />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="mt-5">
        <FakeLogin resetBoundary={resetBoundary} resetQuery={resetQuery} />
        <Login
          loginError={loginError}
          loginPending={loginPending}
          onLogin={onLogin}
        />
      </div>
    </Wrapper>
  );
};

export interface InitLoginRequest {
  country: Country;
  language: Language;
  //return URL will have the following requestr params appended:
  // status=success | abort | error
  // sessionId=<id>
  returnUrl: string;
  MOVE5000?: number;
  DX8000?: number;
  email?: string;
  campaignId?: string;
  voucherId?: string;
  trackingId?: string;
}

function getLoginRequest(
  search: SearchParams,
  lang: Language
): InitLoginRequest {
  return {
    language: lang,
    country: search[QueryKey.COUNTRY] as Country,
    returnUrl: window.location.href.split("?")[0],
    [TerminalType.DX8000]: search[TerminalType.DX8000],
    [TerminalType.MOVE5000]: search[TerminalType.MOVE5000],
    campaignId: search[QueryKey.CAMPAIGN_ID],
    voucherId: search[QueryKey.VOUCHER],
    trackingId: search[QueryKey.TRACKING_ID],
  };
}

function isSweden(search: SearchParams) {
  return search[QueryKey.COUNTRY] === Country.SWEDEN;
}

function isNorway(search: SearchParams) {
  return search[QueryKey.COUNTRY] === Country.NORWAY;
}

const Login: React.FunctionComponent<Props> = ({
  onLogin,
  loginError,
  loginPending,
}) => {
  const { t, i18n } = useTranslation();
  const { search } = useContext<GlobalProps>(GlobalContext);
  const { navigate } = useStoryNavigate();

  const signingMethod =
    CountryToMethod[search[QueryKey.COUNTRY] || Country.SWEDEN];

  const onNext = useCallback(() => {}, []);

  const bankAccountText = useMemo(() => {
    if (isSweden(search) || isNorway(search)) {
      return t(
        "And that you have your company bank account number close at hand."
      );
    }

    return t(
      "And that you have the IBAN of your company bank account close at hand."
    );
  }, [search, t]);

  if (!search[QueryKey.COUNTRY]) {
    navigate(OnboardingPath.CHECKOUT);
  }

  return (
    <IdentificationWrapper onNext={onNext} status={Status.DISABLED}>
      <h2 className={styles.header}>{t("Identify in order to proceed")}</h2>
      <p className={styles.subHeader}>
        {t(
          "In order for us to retrieve the company information we need you to identify yourself. Please use {{method}} to proceed.",
          {
            method: Logos[signingMethod].name,
          }
        )}
      </p>

      <ul className={cx(styles.requirements)}>
        <li className={styles.requirement}>
          <div className={styles.icon}>
            <LuCheckSquare />
          </div>
          <div className={styles.body}>
            {t(
              "Make sure that you have a contact email and personal identity number to all relevant signatories."
            )}
          </div>
        </li>

        <li className={styles.requirement}>
          <div className={styles.icon}>
            <LuCheckSquare />
          </div>
          <div className={styles.body}>{bankAccountText}</div>
        </li>
      </ul>

      <div>
        <Dynamic name={loginError}>
          {loginError && t("Something went wrong. Try again?")}
        </Dynamic>
      </div>
      <Button
        block
        onClick={() =>
          onLogin(getLoginRequest(search, i18n.language as Language))
        }
        status={loginPending ? Status.DISABLED : Status.DEFAULT}
      >
        <ButtonContent signingMethod={signingMethod} />
      </Button>
    </IdentificationWrapper>
  );
};

export const ButtonContent: React.FunctionComponent<{
  signingMethod: SigningMethod;
  buttonText?: string;
}> = ({ signingMethod, buttonText }) => {
  const { t } = useTranslation();

  const text = buttonText || t("Identify");

  let logo = <BankIdLogo fill="#fff" />;

  switch (signingMethod) {
    case SigningMethod.MITID:
      logo = <MittIdLogo fill="#fff" />;
      break;

    case SigningMethod.NBANKID:
      logo = <NBankIdLogo fill="#fff" />;
      break;

    case SigningMethod.FTN:
      logo = <FtnLogo />;
      break;

    default:
      break;
  }

  return (
    <div className={cx(styles.buttonContent, styles[signingMethod])}>
      {logo}
      <span>{text}</span>
    </div>
  );
};

export const IdentificationWrapper: React.FunctionComponent<{
  children: ReactNode;
  onNext: () => void;
  status?: Status;
}> = ({ children, onNext, status = Status.DEFAULT }) => {
  const { onBack } = useStoryNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.idWrapper}>
      {children}

      <div className="flex pt-3 justify-between">
        {onBack ? (
          <Button
            variant="outlined"
            onClick={() => onBack()}
            status={
              status === Status.PENDING ? Status.DISABLED : Status.DEFAULT
            }
            noStatusIcon
          >
            <MdArrowBack /> {t("Back")}
          </Button>
        ) : null}

        <Button onClick={onNext} status={status} noStatusIcon>
          {t("Next")} <MdArrowForward />
        </Button>
      </div>
    </div>
  );
};

const Poll: React.FunctionComponent<PollProps> = ({
  onLogin,
  loginError,
  loginPending,
  sessionId,
  resetQuery,
  resetBoundary,
}) => {
  const { search: params, setSearch: setSavedParams } =
    useContext(GlobalContext);
  const [, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const numberOfTries = useRef<number>(0);
  const [error, setError] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const timer = useRef<number>();
  const { navigate } = useStoryNavigate();

  const pollingFn = useCallback(
    async (id: string) => {
      dataSession
        .pollLogin(id)
        .then(async (response) => {
          if (response.status === LoginStatus.COMPLETE) {
            resetQuery();
            const copy = { ...params };
            delete copy[QueryKey.SESSION];
            setSearchParams("");
            setSavedParams(copy);

            try {
              await queryClient.invalidateQueries({
                queryKey: [...dataStartOnboarding.getCompanySelectionKey()],
                refetchType: "all",
              });
              const data = await queryClient.fetchQuery(
                dataContract.fetchContract()
              );

              if (
                data.contractProgress === ContractProgress.SIGNING ||
                data.contractProgress === ContractProgress.COMPLETE
              ) {
                whereTo(data.contractProgress, navigate);
              }
            } catch (error) {
              setError(true);
            }
            return;
          }

          if (response.status === LoginStatus.ABORTED) {
            setError(true);
            return;
          }

          if (response.status === LoginStatus.ERROR) {
            setError(true);
            return;
          }

          if (numberOfTries.current > MAX_NUMBER_OF_TRIES) {
            setError(true);
            return;
          }

          timer.current = window.setTimeout(() => {
            pollingFn(id);
          }, POLL_TIMER);
        })
        .catch(() => {
          setError(true);
        });
    },
    [queryClient, setSavedParams, setSearchParams, navigate, params, resetQuery]
  );

  useEffect(() => {
    resetBoundary();
    if (!sessionId) {
      return;
    }

    timer.current = window.setTimeout(() => {
      pollingFn(sessionId);
    }, 400);

    return () => {
      window.clearTimeout(timer.current);
    };
  }, [pollingFn, resetBoundary, sessionId]);

  if (loginPending) {
    return (
      <div className={styles.box}>
        {t("Sign in...")}
        <Spinner />
      </div>
    );
  }

  if (loginError) {
    return (
      <div className={styles.box}>
        {t("Something went wrong. Try again?")}
        <div className="mt-6">
          <Button
            block
            onClick={() =>
              onLogin(getLoginRequest(params, i18n.language as Language))
            }
          >
            {t("Sign in")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.box}>
      <Dynamic name={error}>
        {error ? (
          <>
            {t("Something went wrong. Try again?")}
            <div className="mt-6">
              <Button
                block
                onClick={() =>
                  onLogin(getLoginRequest(params, i18n.language as Language))
                }
              >
                {t("Sign in")}
              </Button>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </Dynamic>
    </div>
  );
};
