import React, { useState } from "react";
import styles from "./Information.module.scss";
import cx from "classnames";
import {
  Contract,
  ContractProgress,
  Country,
  OrganizationNumberLabel,
} from "../../../data/models/ContractTypes";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import { Form } from "../../../modules/Forms/Form";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { Banner } from "../../../components/Banner/Banner";
import { Status } from "../../../modules/Forms/FormContext";
import { OnboardingPath } from "../routes";
import { Option } from "../../../components/types";
import { Checkboxes } from "../../../modules/Forms/Checkboxes";
import { StoryButtons } from "../../StoryButtons";
import { useContract } from "../../../hooks/useContract";
import { View } from "../../../modules/View/View";
import { dataContract } from "../../../data/dataContract";
import { useMutation } from "@tanstack/react-query";
import { MdFace } from "react-icons/md";
import { FlagAndCountry } from "../../../modules/Countries/FlagAndCountry";
import { InfoBox } from "../../../components/Boxes/InfoBox";
import { Link } from "../../../components/Link/Link";

function hasConfirmed(contract: Contract) {
  if (contract.contractProgress === ContractProgress.CONTACT_DETAILS) {
    return false;
  }

  if (contract.contractProgress === ContractProgress.COMPANY_DATA_CONFIRM) {
    return false;
  }

  return true;
}

const COMPANY_REGISTRY_SOURCES: Record<Country, string> = {
  [Country.SWEDEN]: "Bolagsverket",
  [Country.DENMARK]: "Erhvervsstyrelsen",
  [Country.NORWAY]: "Brønnøysundregistrene",
  [Country.FINLAND]: "PRH",
  [Country.GB]: "Companies House",
};

const PEP_LINK: Record<Country, string> = {
  [Country.SWEDEN]:
    "https://www.swedishbankers.se/media/3462/1710_pep_eng_ny.pdf",
  [Country.DENMARK]: "https://www.finanstilsynet.dk/lovgivning/pep-listen",
  [Country.NORWAY]:
    "https://www.fatf-gafi.org/en/publications/Fatfrecommendations/Peps-r12-r22.html",
  [Country.FINLAND]:
    "https://julkaisut.valtioneuvosto.fi/bitstream/handle/10024/165354/VM_2024_1.pdf",
  [Country.GB]: "https://www.swedishbankers.se/media/3462/1710_pep_eng_ny.pdf",
};

export const Information: React.FunctionComponent = () => {
  const contract = useContract();
  const [checked, setChecked] = useState<boolean>(hasConfirmed(contract));
  const [pep, setPep] = useState<boolean>(hasConfirmed(contract));
  const [ownership, setOwnership] = useState<boolean>(hasConfirmed(contract));
  const [warning, setWarning] = useState<boolean>(false);
  const [pepWarning, setPepWarning] = useState<boolean>(false);
  const [ownershipWarning, setOwnershipWarning] = useState<boolean>(false);
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const { name, organizationNumber, street, city, postalCode } =
    contract.companyDetails;

  const options: Option<boolean>[] = [
    {
      value: true,
      text: t(
        "I confirm that the above information is correct and up to date."
      ),
    },
  ];
  const pepOptions: Option<boolean>[] = [
    {
      value: true,
      text: t(
        "I confirm that none of the signatories or owners above are considered a politically exposed person (PEP / RCA)."
      ),
    },
  ];

  const ownershipOptions: Option<boolean>[] = [
    {
      value: true,
      text: t(
        "I confirm that the owners above are direct shareholders of the above company"
      ),
    },
  ];

  const {
    // TODO
    mutate: commit,
    isError, // eslint-disable-line
  } = useMutation({
    mutationFn: () => dataContract.commitCompanyDetails(),
    onSuccess: () => {
      navigate(OnboardingPath.CONTACT);
    },
  });

  return (
    <Wrapper>
      <Form
        onSubmit={(event, form) => {
          if (!form.isValid) {
            return;
          }

          if (!checked) {
            setWarning(true);
          }

          if (!pep) {
            setPepWarning(true);
          }

          if (!ownership) {
            setOwnershipWarning(true);
          }

          if (!checked || !pep || !ownership) {
            return;
          }

          commit();
        }}
      >
        <View
          header={t("Review company information")}
          indicator={false}
          size="small"
        >
          <p>
            {t("Here's the information we gathered from {{source}}.", {
              source: COMPANY_REGISTRY_SOURCES[contract.country],
            })}
          </p>

          <h4>{name}</h4>

          <ul className={styles.list}>
            <li>
              <div className={styles.name}>
                {OrganizationNumberLabel[contract.country]}
              </div>
              <div className={styles.value}>{organizationNumber}</div>
            </li>
            <li className={styles.split}>
              <div className={styles.name}>{t("Address")}</div>
              <div className={styles.value}>{street}</div>
            </li>
            <li className={styles.split}>
              <div className={styles.name}>{t("Postal code")}</div>
              <div className={styles.value}>{postalCode}</div>
            </li>
            <li className={styles.split}>
              <div className={styles.name}>{t("City")}</div>
              <div className={styles.value}>{city}</div>
            </li>
            <li className={styles.split}>
              <div className={styles.name}>{t("Country of business")}</div>
              <div className={styles.value}>
                <FlagAndCountry countryCode={contract.country} />
              </div>
            </li>
          </ul>

          <hr className={styles.divider} />

          {contract.selectedSignatories.length ? (
            <div className={cx("mt-2", styles.signees)}>
              <b>{t("Signatories")}</b>
              {contract.selectedSignatories.map((signeeItem) => {
                return (
                  <div className={cx(styles.signee)} key={signeeItem.id}>
                    <MdFace /> {signeeItem.name.toLowerCase()}
                  </div>
                );
              })}
            </div>
          ) : null}

          <div className={cx("mt-2", styles.owners)}>
            <b>{t("Beneficial owners")}</b>
            {contract.beneficialOwners.map((ownerItem) => {
              return (
                <div className={cx(styles.owner)} key={ownerItem.id}>
                  <MdFace /> {ownerItem.name.toLowerCase()}
                </div>
              );
            })}

            <InfoBox className="small mt-2">
              {t("Major shareholders (holding 25% or more) are listed.")}
            </InfoBox>
          </div>

          <Checkboxes
            alt
            className="mt-8 compact small"
            name="agreement"
            onChange={() => {
              setChecked((prev) => !prev);
              if (!checked) {
                setWarning(false);
              }
            }}
            values={checked ? [true] : []}
            options={options}
          />

          <Checkboxes
            alt
            className="mt-1 compact small"
            name="ownership"
            onChange={() => {
              setOwnership((prev) => !prev);
              if (!ownership) {
                setOwnershipWarning(false);
              }
            }}
            values={ownership ? [true] : []}
            options={ownershipOptions}
          />

          <Checkboxes
            alt
            className="mt-1 compact small"
            name="pep"
            onChange={() => {
              setPep((prev) => !prev);
              if (!pep) {
                setPepWarning(false);
              }
            }}
            values={pep ? [true] : []}
            options={pepOptions}
          />

          <InfoBox className="small mt-3">
            {t("Learn more about Politically exposed person and RCA")}{" "}
            <Link external to={PEP_LINK[contract.country]}>
              {t("here")}
            </Link>
          </InfoBox>

          <div className="pt-4">
            <Dynamic name={warning ? "warning" : ""}>
              {warning ? (
                <Banner status={Status.ERROR}>
                  {t("Please update the information in {{entity}}", {
                    entity: COMPANY_REGISTRY_SOURCES[contract.country],
                  })}
                </Banner>
              ) : null}
            </Dynamic>

            <Dynamic name={ownershipWarning && !warning ? "warning" : ""}>
              {ownershipWarning && !warning ? (
                <Banner status={Status.ERROR}>
                  {t(
                    "You can't proceed. At the moment we allow only companies with direct ownership to be onboarded."
                  )}
                </Banner>
              ) : null}
            </Dynamic>

            <Dynamic
              name={
                pepWarning && !warning && !ownershipWarning ? "warning" : ""
              }
            >
              {pepWarning && !warning && !ownershipWarning ? (
                <Banner status={Status.ERROR}>
                  {t(
                    "You can't proceed. At the moment we allow only owners that are not politically exposed to be onboarded."
                  )}
                </Banner>
              ) : null}
            </Dynamic>
          </div>

          <div className="mt-4">
            <StoryButtons />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
