import React, { useState } from "react";
import cx from "classnames";
import styles from "./Acquiring.module.scss";
import { useTranslation } from "react-i18next";
import { Prices } from "../../../data/dataCheckout";
import { Action } from "../../../components/Buttons/Action";
import { MdArrowForwardIos } from "react-icons/md";
import { InfoList } from "../../../components/List/InfoList";
import { getFormattedValue } from "../Summary/CheckoutSummary";
import { Country, Language } from "../../../data/models/ContractTypes";
import { Accordion } from "../../../components/Animations/Accordion";
import { PricingEntryLabel } from "../../../components/PricingEntryLabel/PricingEntryLabel";

interface Props {
  prices: Prices;
  country: Country;
  initiallyOpen?: boolean;
}

export const Acquiring: React.FunctionComponent<Props> = ({
  prices,
  country,
  initiallyOpen = true,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(initiallyOpen);

  // sort by debit, credit, commercial and then alphabetically
  const sortedPrices = prices.acquiring.sort((a, b) => {
    const order = ["debit", "credit", "commercial", "company"];
    const aIndex = order.findIndex((keyword) =>
      a.label.toLowerCase().includes(keyword)
    );
    const bIndex = order.findIndex((keyword) =>
      b.label.toLowerCase().includes(keyword)
    );

    if (aIndex !== bIndex) {
      return aIndex - bIndex;
    }

    return a.label.localeCompare(b.label);
  });

  return (
    <div
      className={cx(styles.acquiring, {
        [styles.open]: open,
      })}
    >
      <div className={styles.top}>
        <div>
          <h6 className="mt-0 mb-0">{t("Pricing - acquiring")}</h6>
          <i className="small">
            {t("{{product}} (Acquiring + terminal)", {
              product: "Worldine One",
            })}
          </i>
        </div>

        <div className={styles.toggle}>
          <Action size="small" onClick={() => setOpen((prev) => !prev)}>
            <MdArrowForwardIos />
          </Action>
        </div>
      </div>

      <Accordion active={open}>
        <div className={cx(styles.list, "full-width")}>
          <InfoList className={styles.split}>
            {sortedPrices.map((item, idx) => {
              return (
                <React.Fragment key={item.label}>
                  <InfoList.Dt
                    className={cx({
                      [styles.tone]: idx % 2 === 1,
                    })}
                  >
                    <PricingEntryLabel value={item.label} />
                  </InfoList.Dt>
                  <InfoList.Dd
                    className={cx("text-right", {
                      [styles.tone]: idx % 2 === 1,
                    })}
                  >
                    {getFormattedValue(
                      item,
                      country,
                      i18n.language as Language
                    )}
                  </InfoList.Dd>
                </React.Fragment>
              );
            })}
          </InfoList>
        </div>
      </Accordion>
    </div>
  );
};
