import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import styles from "./SubscriptionAndMembership.module.scss";
import { useTranslation } from "react-i18next";
import {
  BusinessRisk,
  MembershipOptions,
} from "../../../../data/models/ContractTypes";
import { ButtonPane } from "../../../../components/Buttons/ButtonPane";
import { HiddenInput } from "../../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { SelectableButtons } from "../../../../components/Buttons/SelectableButtons";
import { MinValidator } from "../../../../modules/Forms/validators/MinValidator";
import { MaxValidator } from "../../../../modules/Forms/validators/MaxValidator";
import { Option } from "../../../../components/types";
import { TFunction } from "i18next";

interface Props {
  risk: BusinessRisk;
  onChange: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void;
}

export const OptionLabels = (t: TFunction) => ({
  [MembershipOptions.ZERO__ONE]: t("0-1 months"),
  [MembershipOptions.THREE]: t("1-3 months"),
  [MembershipOptions.YEAR]: t("3-12 months"),
  [MembershipOptions.OTHER]: t("More than one year"),
});

export const SubscriptionAndMembership: React.FunctionComponent<Props> = ({
  risk,
  onChange,
}) => {
  // TODO Move to modules
  const { t } = useTranslation();

  const onClick = useCallback(
    (ev: any, value: any) => {
      onChange(ev, {
        subscriptionAndMembership: {
          ...risk.subscriptionAndMembership,
          [value.key]: value.value,
        },
      });
    },
    [onChange, risk]
  );

  const shareOptions: Option<number>[] = useMemo(() => {
    return [
      {
        value: 25,
        text: t("~ 25%"),
      },
      {
        value: 50,
        text: t("~ 50%"),
      },
      {
        value: 75,
        text: t("~ 75%"),
      },
    ];
  }, [t]);

  const options = useMemo(() => {
    return [
      {
        value: 0,
        text: t("~ 0%"),
      },
      {
        value: 50,
        text: t("~ 50%"),
      },
      {
        value: 100,
        text: t("~ 100%"),
      },
    ];
  }, [t]);

  const totalPercentage = useMemo(() => {
    return Object.entries(MembershipOptions).reduce((acc, cur) => {
      const key = cur[0] as keyof MembershipOptions;

      if (!risk.subscriptionAndMembership) {
        return acc;
      }

      return acc + ((risk.subscriptionAndMembership as any)[key] || 0);
    }, 0);
  }, [risk]);

  return (
    <div className={cx(styles.wrapper)}>
      <b>{t("Does your company offer subscription or membership sales?")}</b>
      <ButtonPane className="mt-1">
        <ButtonPane.Button
          variant={
            risk.hasSubscriptionAndMembership === true ? "selected" : "outlined"
          }
          data={{
            hasSubscriptionAndMembership: true,
          }}
          onClick={onChange}
        >
          {t("Yes")}
        </ButtonPane.Button>

        <ButtonPane.Button
          variant={
            risk.hasSubscriptionAndMembership === false
              ? "selected"
              : "outlined"
          }
          data={{
            hasSubscriptionAndMembership: false,
          }}
          onClick={onChange}
        >
          {t("No")}
        </ButtonPane.Button>
      </ButtonPane>

      <HiddenInput
        value={
          risk.hasSubscriptionAndMembership === undefined ? undefined : true
        }
        validators={[new RequiredValidator(t("Required value"))]}
      />

      <Dynamic name={risk.hasSubscriptionAndMembership ? "yes" : "no"}>
        {risk.hasSubscriptionAndMembership ? (
          <div className="pt-2">
            <div className="pb-4">
              <strong className="small">{t("Part of sales (%)")}</strong>

              <SelectableButtons
                className="mt-1"
                size="small"
                onClick={(ev: any, data: any) => {
                  onChange(ev, {
                    subscriptionAndMembership: {
                      ...risk.subscriptionAndMembership,
                      salesAmount: data.value,
                    },
                  });
                }}
                value={risk.subscriptionAndMembership?.salesAmount ?? ""}
                options={shareOptions}
              />
              <HiddenInput
                value={
                  risk.subscriptionAndMembership?.salesAmount === undefined
                    ? undefined
                    : true
                }
                validators={[new RequiredValidator(t("Required value"))]}
              />

              <div className="mt-6" />

              <i>{t("How large part of these are active in")}</i>

              {Object.entries(MembershipOptions).map(([key, label]) => {
                const value =
                  risk.subscriptionAndMembership &&
                  Number(
                    risk.subscriptionAndMembership[key as MembershipOptions]
                  );

                return (
                  <div key={key} className={cx("mt-2", styles.inputWrapper)}>
                    <strong className="small">{OptionLabels(t)[label]}</strong>
                    <SelectableButtons
                      className="mt-1"
                      size="small"
                      onClick={onClick}
                      value={value ?? ""}
                      options={options}
                      data={{
                        key,
                      }}
                    />
                  </div>
                );
              })}

              <div className="pt-2">
                <HiddenInput
                  value={totalPercentage}
                  validators={[
                    new MinValidator(100, t("Total value must add up to 100%")),
                    new MaxValidator(100, t("Total value can not exceed 100%")),
                  ]}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Dynamic>
    </div>
  );
};
