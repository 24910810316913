import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  useContext,
} from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./View.module.scss";
import { MultiFormContainer } from "../Forms/MultiForm";
import { Validity } from "../../data/models/ContractTypes";
import { FormContainer, FormEvent } from "../Forms/Form";
import { useMultiForm } from "../Forms/MultiFormContext";
import { FormContext } from "../Forms/FormContext";
import { Tag } from "../../components/Chip/Tag";
import { onNextFrame } from "../../components/utils";
import { TFunction } from "i18next";

interface Props {
  header: ReactNode;
  children: ReactNode;
  indicator?: boolean;
  size?: "small" | "regular";
  fixedValidity?: Validity;
}

const TIMER = 300;

function getValidityName(t: TFunction, validity: Validity): string {
  return validity === Validity.VALID ? t("Completed") : t("In progress");
}

export const View: React.FunctionComponent<Props> = ({
  children,
  header,
  indicator = true,
  size = "regular",
  fixedValidity,
}) => {
  const { t } = useTranslation();
  const timer = useRef<number>();
  const multiFormContainer = useMultiForm();
  const form = useContext(FormContext);
  const [showIndicator, setShowIndicator] = useState(false);
  const container = multiFormContainer || form?.formContainer;
  // Default to a valid view if we're missing a surronding form
  const [validity, setValidity] = useState<Validity>(
    fixedValidity || Validity.VALID
  );

  useEffect(() => {
    if (fixedValidity) {
      setShowIndicator(indicator);
      return;
    }

    if (!container) {
      setShowIndicator(indicator);
      return;
    }

    onNextFrame(() => {
      setShowIndicator(indicator);
      setValidity(container.isValid ? Validity.VALID : Validity.PARTIAL);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onUpdate = (
      event: FormEvent,
      formContainer: MultiFormContainer | FormContainer
    ) => {
      if (fixedValidity) {
        return;
      }

      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        setValidity(formContainer.isValid ? Validity.VALID : Validity.PARTIAL);
      }, TIMER);
    };

    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [multiFormContainer, fixedValidity, container]);

  return (
    <section
      className={cx(styles.view, styles[validity], styles[size], {
        [styles.showIndicator]: showIndicator,
      })}
    >
      <div className={styles.header}>
        <div className={styles.sectionName}>
          <h2>{header}</h2>
          <Tag
            className={cx(styles.tag, {
              [styles.show]: validity === Validity.VALID,
            })}
            validity={Validity.VALID}
          >
            {getValidityName(t, Validity.VALID)}
          </Tag>
        </div>
      </div>

      <article className={cx(styles.body)}>{children}</article>
    </section>
  );
};
