import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../../modules/Forms/MultiForm";
import { Validity } from "../../../../data/models/ContractTypes";
import {
  useContract,
  useInvalidateContract,
} from "../../../../hooks/useContract";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../../components/Wrapper";
import { useMutation } from "@tanstack/react-query";
import { View } from "../../../../modules/View/View";
import { SavedAccount } from "../SavedAccount";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { StoryButtons } from "../../../StoryButtons";
import { useSearchParams } from "react-router-dom";
import { Status } from "../../../../modules/Forms/FormContext";
import { Accounts } from "./Accounts";
import { ListBanks } from "./ListBanks";
import { Form } from "../../../../modules/Forms/Form";
import { HiddenInput } from "../../../../modules/Forms/HiddenInput";
import { dataBank } from "../../../../data/dataBank";
import { useStoryNavigate } from "../../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../../routes";

const QUERY_KEY = "status";
const QUERY_RESULT_SUCCESS = "success";
const QUERY_RESULT_ERROR = "error";

export const Roaring: React.FunctionComponent = () => {
  const contract = useContract();
  const [params, setSearchParams] = useSearchParams();
  const invalidate = useInvalidateContract();
  const callbackStatus = params.get(QUERY_KEY);
  const { navigate } = useStoryNavigate();
  const { t } = useTranslation();
  const [iban, setIban] = useState<string>();
  const [status, setStatus] = useState<Status>(
    callbackStatus === QUERY_RESULT_SUCCESS
      ? Status.SUCCESS
      : callbackStatus === QUERY_RESULT_ERROR
      ? Status.ERROR
      : Status.DEFAULT
  );

  const multiFormContainer = useRef<MultiFormContainer>();

  const next = useCallback(() => {}, []);

  useEffect(() => {
    setSearchParams();
  }, [setSearchParams]);

  const {
    mutate: saveIban,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataBank.selectRoaringAccount(iban || ""),
    onSuccess: () => {
      invalidate();
      navigate(OnboardingPath.OWNERS);
    },
  });

  const onSave = useCallback(() => {
    if (!multiFormContainer.current || multiFormContainer.current.isInvalid) {
      multiFormContainer.current?.forceValidation();
      return;
    }

    saveIban();
  }, [saveIban]);

  return (
    <Wrapper>
      <MultiForm multiFormContainer={multiFormContainer}>
        <View
          header={t("Payout account")}
          size="small"
          fixedValidity={
            contract.bankAccount?.iban ? Validity.VALID : undefined
          }
        >
          <p>
            {t(
              "Please login to your bank and select the account where you wish to receive your payouts."
            )}
          </p>

          <SavedAccount bankAccount={contract.bankAccount} next={next} />

          <Dynamic name={status}>
            {Status.ERROR === status ? (
              <GenericError retry={() => setStatus(Status.DEFAULT)}>
                {t("The bank login either failed or was aborted. Try again?")}
              </GenericError>
            ) : null}

            {Status.SUCCESS === status ? (
              <Accounts setStatus={setStatus} iban={iban} setIban={setIban} />
            ) : null}

            {Status.DEFAULT === status ? <ListBanks /> : null}
          </Dynamic>

          <div className="mt-5">
            <Form>
              <HiddenInput
                value={iban ? true : undefined}
                validators={[
                  new RequiredValidator(
                    t("You must select an account in order to proceed")
                  ),
                ]}
              />
            </Form>
          </div>

          <Dynamic name={isError ? "isError" : ""}>
            {isError ? (
              <GenericError
                retry={() => {
                  reset();
                  onSave();
                }}
              >
                {t("We couldn't save the bank account. Try again?")}
              </GenericError>
            ) : null}
          </Dynamic>

          <div className="mt-3">
            <StoryButtons disabled={isPending} action={() => onSave()} />
          </div>
        </View>
      </MultiForm>
    </Wrapper>
  );
};
