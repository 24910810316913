// TODO, update flow so that not all views are
// shown to every customer, e.g. Sole Proprietor

export enum OnboardingPath {
  PRODUCT_SELECTION = "/product-selection",
  CHECKOUT = "/checkout",
  SIGN_IN = "/",
  INFORMATION = "/information",
  CONTACT = "/contact",
  SALES_LOCATIONS = "/sales-location",
  MCC = "/industry",
  DOCUMENT_UPLOAD = "/documents",
  PRODUCTS = "/bundle",
  FINANCIAL = "/financial",
  FINANCIAL_ESTIMATES = "/financial-estimates",
  BUSINESS_RISK = "/risk",
  BANK_ACCOUNT = "/bank",
  OWNERS = "/owners",
  SIGNING_GROUPS = "/signing-groups",
  SIGNATORIES = "/signatories",
  SUMMARY = "/summary",
  COMPLETE = "/complete",
}

/**
/checkout
/
/information // COMPANY_DATA_CONFIRM

/contact // CONTACT_DETAILS

/sales-location // LOCATION_SETUP (location address)
/industry // LOCATION_SETUP (MCC)
/documents // LOCATION_SETUP (not always shown - depends on MCC)
/bundle // LOCATION_SETUP (confirm number of terminals)

/financial // FINANCIAL_KYC
/financial-estimates // FINANCIAL_KYC

/risk // BUSINESS_RISK

/bank // PAYOUT_ACCOUNT

/owners // BENEFICIAL_OWNER

/signing-groups // SIGNATORIES - (not shown for sole proprietary, shown only if multiple signatory groups)
/signatories // SIGNATORIES - not shown for sole proprietary

/summary //CONFIRM_CONTRACT

/complete // SIGNING



public enum MerchantProgress {
	COMPANY_DATA_CONFIRM,
	CONTACT_DETAILS,
	LOCATION_SETUP,
	FINANCIAL_KYC,
	BUSINESS_RISK,
	PAYOUT_ACCOUNT,
	BENEFICIAL_OWNER,
	SIGNATORIES,
	CONFIRM_CONTRACT,
	SIGNING,
	REVIEW,
	PROVISIONING,
	COMPLETE
}
*/
