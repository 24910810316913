import cx from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValidatorConstraints } from "../TextInput";
import styles from "./InputTag.module.scss";
// import { Flipper } from "../../Animations/Flipper";

interface TagProps extends ValidatorConstraints {
  value: string | number;
}

export const InputTag: React.FunctionComponent<TagProps> = ({
  value = "",
  minLength,
  maxLength,
}) => {
  const { t } = useTranslation();
  if (minLength === 0) {
    throw Error(
      "Min required length is 0, remove or use 'required' attribute instead"
    );
  }

  const stringVal = typeof value === "string" ? value : "";

  const data = useMemo(() => {
    if (minLength && stringVal.length >= minLength) {
      if (maxLength) {
        return {
          elem: (
            <div className={cx(styles.tag, "mini")}>
              {stringVal.length} / {maxLength}
            </div>
          ),
          name: "max",
        };
      }

      return {
        elem: <div />,
        name: "empty",
      };
    } else if (
      minLength &&
      stringVal.length !== 0 &&
      stringVal.length < minLength
    ) {
      return {
        elem: (
          <div className={cx(styles.tag, "mini")}>
            {t("Min length")}: {minLength}
          </div>
        ),
        name: "min",
      };
    } else if (maxLength) {
      return {
        elem: (
          <div className={cx(styles.tag, "mini")}>
            {stringVal.length} / {maxLength}
          </div>
        ),
        name: "max",
      };
    }

    return null;
  }, [stringVal.length, minLength, t, maxLength]);

  if (!data) {
    return null;
  }

  return (
    <div className={styles.flipper}>
      {data.elem}
      {/* <Flipper trigger={data.name}>{data.elem}</Flipper> */}
    </div>
  );
};
