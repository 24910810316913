import React, { useMemo } from "react";
import cx from "classnames";
import styles from "./GiftCards.module.scss";
import { useTranslation } from "react-i18next";
import { BusinessRisk } from "../../../../data/models/ContractTypes";
import { ButtonPane } from "../../../../components/Buttons/ButtonPane";
import { HiddenInput } from "../../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { SelectableButtons } from "../../../../components/Buttons/SelectableButtons";
import { Option } from "../../../../components/types";
import { Select } from "../../../../modules/Forms/Select";

interface Props {
  risk: BusinessRisk;
  onChange: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void;
}

export const GiftCards: React.FunctionComponent<Props> = ({
  risk,
  onChange,
}) => {
  const { t } = useTranslation();

  const options: Option<number>[] = useMemo(() => {
    return [
      {
        value: 25,
        text: t("~ 25%"),
      },
      {
        value: 50,
        text: t("~ 50%"),
      },
      {
        value: 75,
        text: t("~ 75%"),
      },
    ];
  }, [t]);

  const periodOptions: Option<string>[] = useMemo(() => {
    return [
      {
        text: "",
        value: "",
      },
      {
        text: t("One month"),
        value: "One month",
      },
      {
        text: t("Six months"),
        value: "Six months",
      },
      {
        text: t("One year"),
        value: "One year",
      },
      {
        text: t("Three years or more"),
        value: "Three years or more",
      },
    ];
  }, [t]);

  return (
    <div className={cx(styles.wrapper)}>
      <b>{t("Does your company sell gift cards?")}</b>
      <ButtonPane className="mt-1">
        <ButtonPane.Button
          variant={risk.hasGiftCards === true ? "selected" : "outlined"}
          data={{
            hasGiftCards: true,
          }}
          onClick={onChange}
        >
          {t("Yes")}
        </ButtonPane.Button>

        <ButtonPane.Button
          variant={risk.hasGiftCards === false ? "selected" : "outlined"}
          data={{
            hasGiftCards: false,
          }}
          onClick={onChange}
        >
          {t("No")}
        </ButtonPane.Button>
      </ButtonPane>

      <HiddenInput
        value={risk.hasGiftCards === undefined ? undefined : true}
        validators={[new RequiredValidator(t("Required value"))]}
      />

      <Dynamic name={risk.hasGiftCards ? "yes" : "no"}>
        {risk.hasGiftCards ? (
          <div className="pt-2">
            <div className="pb-4">
              <strong className="small">{t("Part of sales (%)")}</strong>

              <SelectableButtons
                className="mt-1"
                size="small"
                onClick={(ev: any, data: any) => {
                  onChange(ev, {
                    giftCards: {
                      ...risk.giftCards,
                      salesAmount: data.value,
                    },
                  });
                }}
                value={risk.giftCards?.salesAmount ?? ""}
                options={options}
              />

              <HiddenInput
                value={
                  risk.giftCards?.salesAmount === undefined ? undefined : true
                }
                validators={[new RequiredValidator(t("Required value"))]}
              />

              <div className="mt-4">
                <strong className="small">
                  {t("How long are these valid?")}
                </strong>
                <Select
                  className="mt-1"
                  onChange={(value, name, ev) => {
                    onChange(ev as any, {
                      giftCards: {
                        ...risk.giftCards,
                        validityPeriod: value,
                      },
                    });
                  }}
                  options={periodOptions}
                  value={risk.giftCards?.validityPeriod}
                  validators={[new RequiredValidator(t("Value is required"))]}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Dynamic>
    </div>
  );
};
